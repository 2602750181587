import React from 'react';
import { Link } from 'react-router-dom';
import './WorkCategories.css';

const categories = [
    { title: 'Productie & Logistiek', link: '/vacature-bank' },
    { title: 'Bouw, Installatie & Renovatie', link: '/vacature-bank' },
    { title: 'Zorg', link: '/vacature-bank' },
    { title: 'IT & ICT', link: '/vacature-bank' },
    { title: 'Freelance', link: '/vacature-bank' },
];

const WorkCategories = () => (
    <div className="work-categories-container">
        <div className="category-buttons-container">
            {categories.map((category) => (
                <div key={category.link} className="category-item">
                    <Link to={category.link} className="category-link-item">
                        {category.title}
                    </Link>
                </div>
            ))}
        </div>
        <section className="overview-section">
            <div className="overview-message">
                <h2 className="overview-message-title">Versterk jouw toekomst!</h2>
                <p>
                    Of je nu een carrière hebt gevonden of zoekt naar nieuwe kansen, ontdek onze categorieën en vind de ideale werkplek om je passie te volgen. Begin vandaag nog met je volgende succesverhaal!
                </p>
            </div>
            <div className="category-buttons">
                <Link to="/open-sollicitatie" className="category-button">
                    Open sollicitatie indienen
                </Link>
                <Link to="/vacature-bank" className="category-button">
                    Vacatures bekijken
                </Link>

            </div>
        </section>

    </div>
);

export default WorkCategories;
