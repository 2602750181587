import React, { useState } from 'react';
import './OpenSollicitatie.css';

function OpenSollicitatie() {
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const sendToWhatsApp = () => {
        const { name, category, message } = formData;
        const whatsappMessage = `Open Sollicitatie:\n\nNaam: ${name}\nCategorie: ${category}\nBericht: ${message}`;
        const phoneNumber = '31649226997';  // WhatsApp number without the plus (+) sign
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, '_blank');
    };


    return (
        <div className="open-sollicitation">
            <h2>Begin je open sollicitatie</h2>
            <form className="sollicitation-form">
                <div className="form-group">
                    <label htmlFor="name">Naam</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Categorie</label>
                    <select
                        id="category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled>Selecteer een categorie</option>
                        <option value="Productie & Logistiek">Productie & Logistiek</option>
                        <option value="Bouw, Installatie & Renovatie">Bouw, Installatie & Renovatie</option>
                        <option value="Zorg">Zorg</option>
                        <option value="IT & ICT">IT & ICT</option>
                        <option value="Freelance">Freelance</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="message">Bericht</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <br/>
                <button
                    type="button"
                    className="send-whatsapp-btn"
                    onClick={sendToWhatsApp}
                >
                    Verzenden via WhatsApp
                </button>
            </form>
        </div>
    );
}

export default OpenSollicitatie;
