import React, {useContext} from 'react';
import './App.css';
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import {Navigate, Route, Routes} from "react-router-dom";
import Footer from "./components/Footer";
import Rondleiding from "./components/Rondleiding";
// import TimeSelect from "./components/BookingApp/TimeSelect";
// import DateSelect from "./components/BookingApp/DateSelect";
import useScrollToTop from "./components/hooks/useScrollToTop";
import BegrippenLijst from "./components/BegrippenLijst";
import Personeel from "./components/Personeel";
import Werk from "./components/Werk";
import TalentPool from "./components/TalentPool";
import TalentPoolSignUp from "./components/TalentPoolSignUp";
import TalentPoolExamine from "./components/TalentPoolExamine";
import JobBoard from "./components/JobBoard";
import OpenSollicitatie from "./components/OpenSollicitatie";
import VacatureToevoegen from "./components/VacatureToevoegen";
import Login from "./components/Login";
import {AuthContext} from "./context/AuthContext";
import Dashboard from "./components/Dashboard/Dashboard";
import SuccesfulInquiry from "./components/SuccesfulInquiry";

function App() {
    const { isAuth } = useContext(AuthContext);
    useScrollToTop();

    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/login" element={<Login />} />
                {/*<Route path="/dashboard" element={<Dashboard />} />*/}
                <Route path="/dashboard" element={isAuth ? <Dashboard /> : <Navigate to="/login" />}/>

                <Route path="/vacature-bank" element={<JobBoard />} />
                <Route path="/vacature-toevoegen" element={<VacatureToevoegen />} />
                <Route path="/verzoek-succesvol-ingediend" element={<SuccesfulInquiry />} />

                <Route path="/open-sollicitatie" element={<OpenSollicitatie />} />

                <Route path="/rondleiding" element={<Rondleiding />} />


                <Route path="/begrippen-lijst" element={<BegrippenLijst />} />
                <Route path="/talent-pool" element={<TalentPool />} />
                <Route path="/talent-pool/inschrijven" element={<TalentPoolSignUp />} />
                <Route path="/talent-pool/bekijken" element={<TalentPoolExamine />} />

                <Route path="/talent-pool/bouw" element={<Werk />} />
                <Route path="/talent-pool/zorg" element={<Werk />} />
                <Route path="/talent-pool/ict" element={<Werk />} />
                <Route path="/talent-pool" element={<Werk />} />


                {/*<Route path="/uitgelegd/web-app" element={<ExplainedWebApp />} />*/}
                {/*<Route path="/uitgelegd/artificial-intelligence" element={<ExplainedArtificialIntelligence />} />*/}
                {/*<Route path="/uitgelegd/api" element={<ExplainedAPI />} />*/}


                <Route path="/werk" element={<Personeel />} />
                <Route path="/personeel" element={<Werk />} />







            </Routes>
            <Footer />
        </div>
    );
}

export default App;


